import React, { useEffect, useState } from 'react';
import {APIProvider, Map, AdvancedMarker, InfoWindow, Marker  } from '@vis.gl/react-google-maps';
import CulinaryMapMarker from '../assets/icons/CulinaryMapMarker';
import LodgingMapMarker from '../assets/icons/LodgingMapMarker';
import AttractionMapMarker from '../assets/icons/AttractionMapMarker';
import NightLifeMapMarker from '../assets/icons/NightLifeMapMarker';
import ShoppingMapMarker from '../assets/icons/ShoppingMapMarker';
import { Galleria } from 'primereact/galleria';
import CalendarIcon from '../assets/icons/CalendarIcon';
import EyeIcon from '../assets/icons/EyeIcon';
import noImage from "../assets/images/no-image.jpg";
import locationGif from "../assets/images/locationGif.gif";
import CalendarIconWithoutModal from '../assets/icons/CalendarIconWithoutModal';
import Modal from './Modal';
import LiveLocationIcon from '../assets/icons/LiveLocationIcon';
import LiveLocationIconDenied from '../assets/icons/LiveLocationIconDenied';
import { useSelector } from "react-redux";
import CloseIcon from '../assets/icons/CloseIcon';

export default function LeftMapSection({data, hoverElement, openCalendar, displayMobileMap, setDisplayMobileMap, setMainLoader, setIsCalendarLoading, userId}) {
    const [selectedMarker, setSelectedMarker] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    
    const [averageLatLongs, setAverageLatLongs] = useState();

    const [mapCenter, setMapCenter] = useState(data?.rz_accommodation[0]?.rz_accommodation_full_address ? { lat: data.rz_accommodation[0].rz_accommodation_full_address.lat, lng: data.rz_accommodation[0].rz_accommodation_full_address.lng } : { lat: 0, lng: 0 });
    const [liveLocation, setLiveLocation] = useState(false);
    const [zoom, setZoom] = useState(11);
    const activeIconColor = "#5e17eb";
    const { listingData } = useSelector((state) => state.listingData);

    const handleMarkerClick = (lat, lng, name, tickets, index, gallery, itemData, rzType) => {
        let updatedGallery = [{src: noImage}];
        if (gallery.length > 0 ){
            updatedGallery = gallery.map(item => {
                const { url, ...rest } = item;
                return {
                  ...rest,
                  src: url
                };
            });
        }
        
        setSelectedMarker({lat, lng, name, tickets, index, updatedGallery, itemData, rzType})
    }

    const itemTemplate = (item) => {
        return <img src={item.src} alt={item.name} style={{ width: '100%', display: 'block', maxHeight: "180px" }} />;
    }

    const thumbnailTemplate = (item) => {
        return <img src={item.src} alt={item.name} style={{ display: 'block',maxHeight: "180px" }} />;
    }

    const handleScrolling = (key) =>{
        const element = document.querySelector(`.singleRepeater.${key}`);
        if(element){
            const yOffset = -300;
            const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: yPosition, behavior: 'smooth' });
        }
    }

    const openAddEventMoal = () => {
        setIsModalOpen(true);

        document.querySelector(".listingMapSection").classList.add("openmodal");
    }

    const onCloseModal = () => {
        setIsModalOpen(false); 
        setSelectedMarker(false);
        document.querySelector(".listingMapSection").classList.remove("openmodal");
    }

    const getLiveLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLiveLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });

                    // setMapCenter({
                    //     lat: position.coords.latitude,
                    //     lng: position.coords.longitude,
                    // });

                    // zoomToPercentage(position.coords.latitude, position.coords.longitude, data.rz_accommodation[0].rz_accommodation_full_address.lat, data.rz_accommodation[0].rz_accommodation_full_address.lng);

                },
                (error) => {
                    console.error('Error getting live location', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }

    const closeLiveLocation = () => {
        setLiveLocation(false);
        // setMapCenter(data?.rz_accommodation[0]?.rz_accommodation_full_address ? { lat: data.rz_accommodation[1].rz_accommodation_full_address.lat, lng: data.rz_accommodation[1].rz_accommodation_full_address.lng } : { lat: 0, lng: 0 })
        // setZoom(8)
        // zoomToPercentage(averageLatLongs?.lat1, averageLatLongs?.lng1,  averageLatLongs?.lat2, averageLatLongs?.lng2)
        // setTimeout(() => {
        //     getMarkers(data);
        // }, 100)
    }

    function haversineDistance(lat1, lon1, lat2, lon2) {
        const R = 6371; // Radius of the Earth in kilometers
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(lat1 * (Math.PI / 180)) *
            Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in kilometers
        return distance;
    }

    function getZoomLevel(lat1, lon1, lat2, lon2) {
        const distance = haversineDistance(lat1, lon1, lat2, lon2);
        // console.log("distance: ", distance);
        const maxZoom = 20; // Street-level zoom
        const minZoom = 1;  // World-level zoom
      
        // Approximate distance ranges (in km) for zoom levels
        if (distance > 10000) return 2; // World-level zoom
        if (distance > 1000) return 2;  // Continental zoom
        if (distance > 500) return 3;  // Continental zoom
        if (distance > 400) return 4;  // Continental zoom
        if (distance > 300) return 5;   // Country-level zoom
        if (distance > 250) return 6;   // Country-level zoom
        if (distance > 200) return 7;   // Country-level zoom
        if (distance > 180) return 7;   // Country-level zoom
        if (distance > 150) return 8;   // Country-level zoom
        if (distance > 100) return 9;   // Country-level zoom
        if (distance > 50) return 10;   // State-level zoom
        if (distance > 10) return 11;   // City-level zoom
        if (distance > 5) return 12;   // City-level zoom
        if (distance > 3) return 12;   // City-level zoom
        if (distance > 1) return 13;    // Neighborhood zoom

        return maxZoom;                 // Close-up street-level zoom
    }

    function zoomToPercentage(lat1, lon1, lat2, lon2) {
        const zoomLevel = getZoomLevel(lat1, lon1, lat2, lon2)
        const maxZoom = 20;
        var zoom =  (zoomLevel);
        
        setZoom(zoom)
    }

    const getMarkers = (data) => {
        const lats = [];
        const longs = [];

        Promise.all(data && data?.rz_accommodation && data?.rz_accommodation.map((item, key) => {
            if(item?.rz_accommodation_full_address?.lat){
                lats.push(item?.rz_accommodation_full_address?.lat)
                longs.push(item?.rz_accommodation_full_address?.lng)
            }
        }))

        Promise.all(data && data?.rz_culinary && data?.rz_culinary.map((item, key) => {
            if(item?.rz_culinary_full_address?.lat){
                lats.push(item?.rz_culinary_full_address?.lat)
                longs.push(item?.rz_culinary_full_address?.lng)
            }
        }))

        Promise.all(data && data?.rz_attractions && data?.rz_attractions.map((item, key) => {
            if(item?.rz_attractions_full_address?.lat){
                lats.push(item?.rz_attractions_full_address?.lat)
                longs.push(item?.rz_attractions_full_address?.lng)
            }
        }))

        Promise.all(data && data?.rz_night && data?.rz_night.map((item, key) => {
            if(item?.rz_night_life_full_address?.lat){
                lats.push(item?.rz_night_life_full_address?.lat)
                longs.push(item?.rz_night_life_full_address?.lng)
            }
        }))

        Promise.all(data && data?.rz_shopping && data?.rz_shopping.map((item, key) => {
            if(item?.rz_shopping_full_address?.lat){
                lats.push(item?.rz_shopping_full_address?.lat)
                longs.push(item?.rz_shopping_full_address?.lng)
            }
        }))

        var averageLat = (Math.max(...lats) + Math.min(...lats)) / 2;
        var averageLng = (Math.max(...longs) + Math.min(...longs)) / 2;

        if(liveLocation){
            lats.push(liveLocation?.lat)
            longs.push(liveLocation?.lng)

            averageLat = (Math.max(...lats) + Math.min(...lats)) / 2;
            averageLng = (Math.max(...longs) + Math.min(...longs)) / 2;
        }

        // console.log("lats: ", lats);
        // console.log("longs: ", longs);

        setAverageLatLongs({lat1: Math.max(...lats), lng1: Math.max(...longs), lat2: Math.min(...lats), lng2: Math.min(...longs)});

        zoomToPercentage(Math.max(...lats), Math.max(...longs),  Math.min(...lats), Math.min(...longs))

        // setMapCenter(data?.rz_accommodation[0]?.rz_accommodation_full_address ? { lat: data.rz_accommodation[0].rz_accommodation_full_address.lat, lng: data.rz_accommodation[0].rz_accommodation_full_address.lng } : { lat: 0, lng: 0 })

        setMapCenter({
            lat: averageLat,
            lng: averageLng
        })

        // console.log("{ lat: data.rz_accommodation[1].rz_accommodation_full_address.lat, lng: data.rz_accommodation[1].rz_accommodation_full_address.lng }: ", { lat: data.rz_accommodation[1].rz_accommodation_full_address.lat, lng: data.rz_accommodation[1].rz_accommodation_full_address.lng });
    }

    useEffect(() => {
        if(data){
            getMarkers(data);
        }
    }, [data]);

    useEffect(() => {
        // if(liveLocation){
            getMarkers(data);
        // }
    }, [liveLocation]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Assuming mobile devices are <= 768px
        };
        
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <>
        <div style={{ height: '750px', width: '100%' }} className={displayMobileMap ? "mobileMapOpened" : ""}>
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY} onLoad={() => console.log('Maps API has loaded.')}>
                <Map
                    defaultZoom={8}
                    defaultCenter={mapCenter}
                    center={mapCenter}
                    zoom={zoom}
                    scrollwheel={true}
                    // zoomControl={false}
                    onCameraChanged={ (ev) => {
                            setMapCenter(ev.detail.center)
                            setZoom(ev.detail.zoom)
                            // console.log('camera changed:', ev.detail.center, 'zoom:', ev.detail.zoom)
                        }
                    }
                    mapId={"ADVANCE_MARKER"}
                    options={{
                        streetViewControl: false,
                        fullscreenControl: !isMobile
                    }}
                >

                    {liveLocation && (
                        <AdvancedMarker position={liveLocation} zIndex={999} >
                            <img src={locationGif} style={{width: "40px"}} />
                        </AdvancedMarker>
                    )}

                    {data?.rz_accommodation && data?.rz_accommodation.map((item, key) => (
                        item?.rz_accommodation_full_address?.lat && (

                            <AdvancedMarker position={{lat: item?.rz_accommodation_full_address?.lat, lng: item?.rz_accommodation_full_address?.lng}} zIndex={(hoverElement.rzType === "rz_accommodation" && item?.rz_accommodation_full_address?.place_id === hoverElement.placeId) ? 999 : 1} 
                                onClick={() => handleMarkerClick(item?.rz_accommodation_full_address?.lat, item?.rz_accommodation_full_address?.lng, item?.rz_accommodation_name, item?.rz_accommodation_reservaction_link, key, item?.rz_accommodation_gallery, item, "rz_accommodation")}  
                            >

                                <LodgingMapMarker height={40} width={40} color={(hoverElement.rzType === "rz_accommodation" && item?.rz_accommodation_full_address?.place_id === hoverElement.placeId) && (activeIconColor)} />

                            </AdvancedMarker>
                        )
                    ))}

                    {data?.rz_culinary && data?.rz_culinary.map((item, key) => (
                        item?.rz_culinary_full_address?.lat && (

                            <AdvancedMarker position={{lat: item?.rz_culinary_full_address?.lat, lng: item?.rz_culinary_full_address?.lng}} zIndex={(hoverElement.rzType === "rz_culinary" && item?.rz_culinary_full_address?.place_id === hoverElement.placeId) ? 999 : 1} 
                                onClick={() => handleMarkerClick(item?.rz_culinary_full_address?.lat, item?.rz_culinary_full_address?.lng, item?.rz_culinary_name, item?.rz_culinary_reservaction_link, key, item?.rz_culinary_gallery, item, "rz_culinary")}
                            >

                                <CulinaryMapMarker height={40} width={40} color={(hoverElement.rzType === "rz_culinary" && item?.rz_culinary_full_address?.place_id === hoverElement.placeId) && (activeIconColor)} />

                            </AdvancedMarker>
                        )
                    ))}

                    {data?.rz_attractions && data?.rz_attractions.map((item, key) => (
                        item?.rz_attractions_full_address?.lat && (

                            <AdvancedMarker position={{lat: item?.rz_attractions_full_address?.lat, lng: item?.rz_attractions_full_address?.lng}} zIndex={(hoverElement.rzType === "rz_attractions" && item?.rz_attractions_full_address?.place_id === hoverElement.placeId) ? 999 : 1} 
                                onClick={() => handleMarkerClick(item?.rz_attractions_full_address?.lat, item?.rz_attractions_full_address?.lng, item?.rz_attractions_name, item?.rz_attractions_reservaction_link, key, item?.rz_attractions_gallery, item, "rz_attractions")}
                            >

                                <AttractionMapMarker height={40} width={40} color={(hoverElement.rzType === "rz_attractions" && item?.rz_attractions_full_address?.place_id === hoverElement.placeId) && (activeIconColor)} />

                            </AdvancedMarker>
                        )
                    ))}

                    {data?.rz_night && data?.rz_night.map((item, key) => (
                        item?.rz_night_life_full_address?.lat && (

                            <AdvancedMarker position={{lat: item?.rz_night_life_full_address?.lat, lng: item?.rz_night_life_full_address?.lng}} zIndex={(hoverElement.rzType === "rz_night" && item?.rz_night_life_full_address?.place_id === hoverElement.placeId) ? 999 : 1} 
                                onClick={() => handleMarkerClick(item?.rz_night_life_full_address?.lat, item?.rz_night_life_full_address?.lng, item?.night_life_name, item?.rz_night_life_reservaction_link, key, item?.night_life_gallery, item, "rz_night")}
                            >

                                <NightLifeMapMarker height={40} width={40} color={(hoverElement.rzType === "rz_night" && item?.rz_night_life_full_address?.place_id === hoverElement.placeId) && (activeIconColor)} />

                            </AdvancedMarker>
                        )
                    ))}

                    {data?.rz_shopping && data?.rz_shopping.map((item, key) => (
                        item?.rz_shopping_full_address?.lat && (
                            
                            <AdvancedMarker position={{lat: item?.rz_shopping_full_address?.lat, lng: item?.rz_shopping_full_address?.lng}} zIndex={(hoverElement.rzType === "rz_shopping" && item?.rz_shopping_full_address?.place_id === hoverElement.placeId) ? 999 : 1} 
                                onClick={() => handleMarkerClick(item?.rz_shopping_full_address?.lat, item?.rz_shopping_full_address?.lng, item?.rz_shopping_name, item?.rz_shopping_reservaction_link, key, item?.shopping_gallery, item, "rz_shopping")}
                            >

                                <ShoppingMapMarker height={40} width={40} color={(hoverElement.rzType === "rz_shopping" && item?.rz_shopping_full_address?.place_id === hoverElement.placeId) && (activeIconColor)} />

                            </AdvancedMarker>
                        )
                    ))}

                    {selectedMarker && (
                        <InfoWindow position={{lat: selectedMarker.lat, lng: selectedMarker.lng}} onCloseClick={() => setSelectedMarker(false)}
                            style={{padding: "0px"}}
                        >
                                <div className='mapGallerySection'>
                                    <Galleria 
                                        value={selectedMarker.updatedGallery} 
                                        numVisible={5} 
                                        circular 
                                        style={{ maxWidth: '280px', maxHeight: "180px" }}
                                        showThumbnails={false} 
                                        showItemNavigators 
                                        item={itemTemplate} 
                                        thumbnail={thumbnailTemplate} 
                                    />
                                </div>

                                <div className='mapContentSection'>
                                    <h2 className='markerHeading'>{selectedMarker.name}</h2>

                                    <a 
                                        href={`https://www.google.com/maps?q=${selectedMarker.lat},${selectedMarker.lng}`} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        style={{ color: '#007bff', textDecoration: 'underline', display: 'block'}}
                                        className='viewOnmap'
                                    >לצפייה במפה</a>

                                    <div className='leftBottomMarker'>
                                        {(listingData?.id !== 17147 && userId !== 0) && (
                                            <div className='openCalendar' onClick={openAddEventMoal}>
                                                <CalendarIconWithoutModal />
                                            </div>
                                        )}
                                        <div className={`scrollEyeIcon`} onClick={() => handleScrolling(`${selectedMarker.rzType}${selectedMarker.index}`)} >
                                            <EyeIcon />
                                        </div>
                                    </div>
                                </div>
                        </InfoWindow>
                    )}

                </Map>
            </APIProvider>

            {liveLocation ? (
                <div className='liveLocationIconDenied mapLocationIcon' onClick={closeLiveLocation}>
                    <LiveLocationIconDenied />
                </div>
            ) : (
                <div className='liveLocationIcon mapLocationIcon' onClick={getLiveLocation}>
                    <LiveLocationIcon />
                </div>
            )}

            {displayMobileMap && (
                <div className='closeMobileMapIcon' onClick={() => setDisplayMobileMap(false)}>
                    <CloseIcon />
                </div>
            )}

        </div>

        {(isModalOpen && selectedMarker) && (
            <Modal
                isOpen={isModalOpen}
                onClose={onCloseModal}
                name={selectedMarker.itemData[selectedMarker.rzType][`${selectedMarker.rzType}_name`]} 
                accId={selectedMarker.rzType} 
                index={selectedMarker.index} 
                itemData={selectedMarker.itemData}
                openCalendar={openCalendar}
                setMainLoader={setMainLoader}
                setIsCalendarLoading={setIsCalendarLoading}
            />
        )}
   </> )
}
