import Header from "../components/Header";
import ListingFooter from "../components/ListingFooter";
import SiteFooter from "../components/SiteFooter";
import { useEffect, useState } from "react";
// import { Helmet } from 'react-helmet-async';

const Layout = ({children, userId,listingData, showBlur, handleCalendarButtonClick, openMobileMap, isDisplayMobileMap, showCalendar, metaData}) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    
    const removeHtmlTags = (str) => {
        return str.replace(/<[^>]*>/g, '');
    };

    // useEffect(() => {
        
    //     const metaDescription = document.querySelector("meta[name='description']");
    //     console.log("metaDescription: ", metaDescription);
    //     if (metaDescription) {
    //         if(metaDescription.getAttribute("data-rh") != "true"){
    //             metaDescription.remove();
    //         }
    //     }

    //     const metaDescriptionOg = document.querySelector("meta[property='og:description']");
    //     if (metaDescriptionOg) {
    //         if(metaDescriptionOg.getAttribute("data-rh") != "true"){
    //             metaDescriptionOg.remove();
    //         }
    //     }

    //     const metaTitleOg = document.querySelector("meta[property='og:title']");
    //     if (metaTitleOg) {
    //         if(metaTitleOg.getAttribute("data-rh") != "true"){
    //             metaTitleOg.remove();
    //         }
    //     }
    //   }, []);

    return (
    <>
        {/* <Helmet>
            <title>{metaData?.title}</title>
            <meta
                name="description"
                content={removeHtmlTags(metaData?.description)}
            />
            <meta
                property="og:description"
                content={removeHtmlTags(metaData?.description)}
            />
            <meta property="og:title" content={metaData?.title} />

            <meta property="og:image" content={metaData?.image} />
        </Helmet> */}
        {/* <Helmet>
            <title>{metaData?.title}</title>
            <meta name="description" content={removeHtmlTags(metaData?.description)} />
            <meta property="og:title" content={metaData?.ogtitle || metaData?.title} />
            <meta property="og:description" content={removeHtmlTags(metaData?.ogdescription || metaData?.description)} />
            <meta property="og:image" content={metaData?.image} />
            <meta property="og:type" content={metaData?.type || "website"} />
        </Helmet> */}

        <div className="siteMainWrapper">
            <Header isMobileMenuOpen={isMobileMenuOpen} listingData={listingData}/>

            <div className="siteContent">
                {children}
            </div>

            {listingData ? (
                <ListingFooter 
                    userId={userId} 
                    listingData={listingData} 
                    showBlur={showBlur} 
                    handleCalendarButtonClick={handleCalendarButtonClick} 
                    openMobileMap={openMobileMap} 
                    isDisplayMobileMap={isDisplayMobileMap} 
                    showCalendar={showCalendar} 
                    setIsMobileMenuOpen={setIsMobileMenuOpen}
                    isMobileMenuOpen={isMobileMenuOpen}
                    />
                ) : (
                    <SiteFooter 
                    userId={userId} 
                    setIsMobileMenuOpen={setIsMobileMenuOpen}
                    isMobileMenuOpen={isMobileMenuOpen}
                    />
                )}
        </div>
    </>);
}

export default Layout;