import axios from 'axios';
import React, { useEffect, useState } from 'react';
import CalendarIcon from "../assets/icons/simpleCalendarIcon";
import { Calendar } from 'primereact/calendar';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserData } from '../utils/reducers/user.reducer';
import { updateListingData, updateCalendarDate } from '../utils/reducers/listing.reducer';
import { GoogleMap, useLoadScript, Autocomplete } from '@react-google-maps/api';
import AlertIcon from '../assets/icons/AlertIcon';
import { addLocale } from 'primereact/api';

addLocale('he', {
    firstDayOfWeek: 0,
    dayNames: ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'],
    dayNamesShort: ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש'],
    dayNamesMin: ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש'],
    monthNames: ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'],
    monthNamesShort: ['ינו', 'פבר', 'מרץ', 'אפר', 'מאי', 'יונ', 'יול', 'אוג', 'ספט', 'אוק', 'נוב', 'דצ'],
    today: 'היום',
    clear: 'נקה'
});

const Modal = ({ isOpen, onClose, onSave, name, otherFields, start, userId, accId, index, itemData ,isSidebarCalendar=false, oldLocation=false, isUpdate=false, addAll=false, day=0, openCalendar, disableTitle=false, isDaysActivity=false, reminderVal=false, isRepeaterEvent=false, oldEndDate, setMainLoader, setIsCalendarLoading }) => {
    const [date, setDate] = useState('');
    const [startTime, setStartTime] = useState('00:00');
    const [sidebarStartDate, setSidebarStartDate] = useState('');
    const [endTime, setEndTime] = useState('00:00');
    const [reminder, setReminder] = useState("");
    const [title, setTitle] = useState('');
    const [location, setLocation] = useState('');
    const [geoLocation, setGeoLocation] = useState('');
    const { listingData } = useSelector((state) => state.listingData);
    const { userData } = useSelector((state) => state.userData);
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const libraries = ['places'];
    const [autocomplete, setAutocomplete] = useState(null);
    const [eventExists, setEventExists] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [isEndDateDisbaled, setIsEndDateDisbaled] = useState(true);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
        libraries,
    });

    useEffect(() => {
        if (isOpen && !addAll) {
            setDate('');

            if(reminderVal){
                setReminder(reminderVal);
            }

            if (otherFields) {
                setTitle(name || '');
                setLocation('');

                if (oldLocation) {
                    setLocation(oldLocation);
                }

                if (!isDaysActivity) {
                    let startDateObj = new Date(start);
                    let formatted = `${startDateObj.getHours() < 10 ? `0${startDateObj.getHours()}` : startDateObj.getHours()}:00`;
                    var formattedEndTime = "00:00";

                    if(isUpdate){
                        let endDateObj = new Date(oldEndDate);
                        formattedEndTime = `${endDateObj.getHours() < 10 ? `0${endDateObj.getHours()}` : endDateObj.getHours()}:00`;
                    }else{
                        formattedEndTime = `${startDateObj.getHours() < 10 + 1 ? `0${startDateObj.getHours() + 1}` : startDateObj.getHours() + 1}:00`;
                    }
                    
                    setStartTime(formatted);
                    setEndTime(formattedEndTime);
                } else {
                    setStartTime(`${parseInt(start)}:00`);
                    setEndTime(`${parseInt(start) + 1}:00`);
                }

                setSidebarStartDate(start);
            } else {
                setTitle(itemData[`${accId}_name`]);
                setLocation(itemData[`${accId}_full_address`].address);
            }
        }
    }, [isOpen, otherFields, name]);

    const timeOptions = [
        "00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00",
        "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00",
        "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"
    ];

    const reminderTimeOptions = [
        "00:30", "01:00", "02:00", "03:00"
    ];

    const handlePlaceChange = () => {
        if (autocomplete) {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                setGeoLocation({
                    query: place.formatted_address,
                    placeId: place.place_id
                });
                setLocation(place.formatted_address); // Optional: Set the formatted address
            }
        }
    };

    const getCalendarData = async () => {
        setIsCalendarLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/wp-json/calendar-diary-api/user_data`,
                {
                    params: { listing_id: listingData?.id, "user_id": userData?.userId },
                    headers: { "Content-Type": "application/json" },
                }
            );

            setIsCalendarLoading(false);

            if (response?.data?.status !== "error") {
                dispatch(updateListingData({ sidebarCalendarData: response.data }))
            }

        } catch (err) {
            setIsCalendarLoading(false);
            console.error("Error fetching recommendation data:", err);
        }
    };

    const handleSave = async (item) => {
        var errorPayload = {}
        if (!isSidebarCalendar) {
            if (!date) {
                errorPayload = {
                    ...errorPayload,
                    "date": {
                        isError: true,
                        message: "Please select a date"
                    }
                }

                setErrors(errorPayload);

                return false;
            }
        }

        var dateObj = new Date(date);
        if (isSidebarCalendar) {
            dateObj = new Date(sidebarStartDate);
        }

        const formattedDate = `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
        const start = startTime;
        const end = endTime;

        var locationUrl = '';
        var reservaction_link = '';
        var website_link = '';

        if (otherFields) {
            locationUrl = `https://www.google.com/maps/search/?api=1&query=${geoLocation.query}&query_place_id=${geoLocation.placeId}`;

            if (accId !== "" && accId !== undefined && oldLocation === false) {
                reservaction_link = listingData?.acf?.[accId][index][`${accId}_reservaction_link`];
                website_link = listingData?.acf?.[accId][index][`${accId}_website_link`];
            }

        } else {
            locationUrl = `https://www.google.com/maps/search/?api=1&query=${item[`${accId}_full_address`].address}&query_place_id=${item[`${accId}_full_address`].place_id}`;
            reservaction_link = item[`${accId}_reservaction_link`];
            website_link = item[`${accId}_website_link`];
        }

        const diaryEntry = {
            listing_id: listingData.id,
            acc_id: (accId !== "" && accId !== undefined) ? `${accId}${parseInt(index) + 1}` : "",
            calendar_date: formattedDate,
            start_time: start,
            end_time: end,
            calendar_reminder: reminder,
            user_id: userData?.userId,
            acc_title: title,
            location: location,
            location_url: locationUrl,
            reservaction_link: reservaction_link,
            website_link: website_link,
        };

        try {
            setMainLoader(true);
            if (isUpdate) {
                diaryEntry.event_id = isUpdate;
                const response = await axios.put(`${process.env.REACT_APP_API_URL}/wp-json/calendar-diary-api/edit`, diaryEntry);
            } else {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/wp-json/calendar-diary-api/add`, diaryEntry);
            }
             getCalendarData();
             dispatch(updateCalendarDate(dateObj));
             openCalendar(true);
            
             setMainLoader(false);
             onClose();
        } catch (error) {
             setMainLoader(false);
            
            if (error?.response?.data?.code === "event_exists") {
                setConfirmationModal(true);
            }
            
            console.error('API error:', error);
        }
    };

    const handleAllDaysSave = async (items) => {
        const allDaysEntry = [];
        const dateObj = new Date(date);
        const formattedDate = `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
        const diaryEntry = [];

        Promise.all(
            items && items.map((singleRec, singleRecKey) => {
                if (day === singleRec.day) {

                    if (singleRec.day_recommendation) {
                        Promise.all(singleRec[singleRec.rzName].map((item, key) => {

                            let locationUrl = `https://www.google.com/maps/search/?api=1&query=${item[`${singleRec.rzName}_full_address`].address}&query_place_id=${item[`${singleRec.rzName}_full_address`].place_id}`;

                            diaryEntry.push({
                                listing_id: listingData.id,
                                acc_id: `${singleRec.rzName}${singleRec.index}`,
                                calendar_date: formattedDate,
                                start_time: singleRec.startTime && singleRec.startTime.replace(".", ":"),
                                end_time: parseInt(singleRec.startTime) + 1 + ":00",
                                // calendar_reminder: reminder,
                                user_id: userData?.userId,
                                acc_title: item[`${singleRec.rzName}_name`],
                                location: item[`${singleRec.rzName}_full_address`].address,
                                location_url: locationUrl,
                                reservaction_link: item[`${singleRec.rzName}_reservaction_link`],
                                website_link: item[`${singleRec.rzName}_website_link`],
                                action_type: "multiple",
                                day: `day${parseInt(singleRec.day) + 1}`
                            });
                        }))
                    } else {
                        diaryEntry.push({
                            listing_id: listingData.id,
                            calendar_date: formattedDate,
                            start_time: singleRec.startTime && singleRec.startTime.replace(".", ":"),
                            end_time: parseInt(singleRec.startTime) + 1 + ":00",
                            // calendar_reminder: reminder,
                            user_id: userData?.userId,
                            acc_title: singleRec.title,
                            action_type: "multiple",
                            day: `day${parseInt(singleRec.day) + 1}`
                        });
                    }
                }
            })
        )

        if (diaryEntry.length > 0) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/wp-json/calendar-diary-api/add/multiple`, diaryEntry);

                if (response?.data?.status === "exists") {
                    // confirmOverRide(items);
                    setConfirmationModal(true);
                    return false;
                }

                if (response) {
                    getCalendarData();
                    onClose();
                    openCalendar(true);
                }

            } catch (err) {
                if (err?.response?.data?.status === "exists") {
                    // confirmOverRide(items);
                    setConfirmationModal(true);
                }
            }
        }
    }

    const confirmOverRide = async (items) => {
        const allDaysEntry = [];
        const dateObj = new Date(date);
        const formattedDate = `${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;
        const diaryEntry = [];

        Promise.all(
            items && items.map((singleRec, singleRecKey) => {
                if (day === singleRec.day) {

                    if (singleRec.day_recommendation) {
                        Promise.all(singleRec[singleRec.rzName].map((item, key) => {

                            let locationUrl = `https://www.google.com/maps/search/?api=1&query=${item[`${singleRec.rzName}_full_address`].address}&query_place_id=${item[`${singleRec.rzName}_full_address`].place_id}`;

                            diaryEntry.push({
                                listing_id: listingData.id,
                                acc_id: `${singleRec.rzName}${singleRec.index}`,
                                calendar_date: formattedDate,
                                start_time: singleRec.startTime && singleRec.startTime.replace(".", ":"),
                                end_time: parseInt(singleRec.startTime) + 1 + ":00",
                                // calendar_reminder: reminder,
                                user_id: userData?.userId,
                                acc_title: item[`${singleRec.rzName}_name`],
                                location: item[`${singleRec.rzName}_full_address`].address,
                                location_url: locationUrl,
                                reservaction_link: item[`${singleRec.rzName}_reservaction_link`],
                                website_link: item[`${singleRec.rzName}_website_link`],
                                event_type: "overwrite",
                                action_type: "multiple",
                                day: `day${parseInt(singleRec.day) + 1}`
                            });
                        }))
                    } else {
                        diaryEntry.push({
                            listing_id: listingData.id,
                            calendar_date: formattedDate,
                            start_time: singleRec.startTime && singleRec.startTime.replace(".", ":"),
                            end_time: parseInt(singleRec.startTime) + 1 + ":00",
                            // calendar_reminder: reminder,
                            user_id: userData?.userId,
                            acc_title: singleRec.title,
                            event_type: "overwrite",
                            action_type: "multiple",
                            day: `day${parseInt(singleRec.day) + 1}`
                        });
                    }
                }
            })
        )

        if (diaryEntry.length > 0) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/wp-json/calendar-diary-api/add/multiple`, diaryEntry);

                if (response) {
                    getCalendarData();
                    setConfirmationModal(false);
                    onClose();
                    openCalendar(true);
                }

            } catch (err) {
                console.log("error: ", err);
            }
        }
    }

    const cancelConfirmation = () => {
        setConfirmationModal(false);
    }

    const isOptionDisabled = (time, type) => {
        if (type === "end") {
            return startTime && time <= startTime;
        }

        return false;
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-contents" onClick={e => e.stopPropagation()}>
                <div className='modal_header'>
                    <span className="close" onClick={onClose}>&times;</span>
                </div>
                <div className='modal_body'>
                    <h2>הוספה ליומן</h2>


                    {otherFields === true && (
                        <>
                            <div className="modal-field">
                                <label>כותרת</label>
                                <input
                                    type="text"
                                    value={title}
                                    onChange={e => setTitle(e.target.value)}
                                    placeholder="Enter title"
                                    readOnly={disableTitle || isRepeaterEvent}
                                />
                            </div>

                            <div className="modal-field">
                                <label>מיקום</label>
                                {isLoaded && (
                                    <Autocomplete
                                        onLoad={(autocomplete) => setAutocomplete(autocomplete)}
                                        onPlaceChanged={(place) => handlePlaceChange(place)}
                                    >
                                        <input
                                            type="text"
                                            value={location}
                                            onChange={e => setLocation(e.target.value)}
                                            placeholder="Enter location"
                                            readOnly={isRepeaterEvent}
                                            style={{pointerEvents: isRepeaterEvent ? "none" : "all"}}
                                        />
                                    </Autocomplete>
                                )}
                            </div>
                        </>
                    )}
                    {!isSidebarCalendar && (
                        <div className="modal-field">
                            <label>תאריך</label>
                            <div className='dateInput position-relative'>
                                <Calendar value={date} locale="he" onChange={(e) => { setDate(e.value); setErrors({}) }} />
                                <span className="cal_icon position-absolute">
                                    <CalendarIcon />
                                </span>
                            </div>
                        </div>
                    )}

                    {errors?.date?.isError && (
                        <p className='formErrorMsg'>{errors?.date?.message}</p>
                    )}

                    {!addAll && (
                        <div className='row'>
                            <div className='col-6'>
                                <div className="modal-field">
                                    <label>שעת התחלה</label>
                                    <div className='selectfield'>
                                        <select
                                            value={startTime}
                                            onChange={e => { setStartTime(e.target.value); setIsEndDateDisbaled(false); setEndTime(timeOptions[timeOptions.indexOf(e.target.value) !== -1 ? timeOptions.indexOf(e.target.value) + 1 : e.target.value]) }}
                                            // disabled={isDaysActivity}
                                        >
                                            {/* <option value="" disabled>Select time</option> */}
                                            {timeOptions.map(time => (
                                                <option
                                                    key={time}
                                                    value={time}
                                                    disabled={isOptionDisabled(time, "start")}
                                                >
                                                    {time}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="modal-field">
                                    <label>שעת סיום</label>
                                    <div className='selectfield'>
                                        <select
                                            value={endTime}
                                            onChange={e => setEndTime(e.target.value)}
                                            // disabled={isDaysActivity || isEndDateDisbaled}
                                        >
                                            {/* <option value="" disabled>Select time</option> */}
                                            {timeOptions.map(time => (
                                                <option
                                                    key={time}
                                                    value={time}
                                                    disabled={isOptionDisabled(time, "end")}
                                                >
                                                    {time}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {!addAll && (
                        <div className="modal-field">
                            <label className='d-flex align-items-center'>
                                <span>תזכורת</span>
                                <div className="mytooltip">
                                    <i className="fa-solid fa-circle-question"></i>
                                    <span className="tooltiptext">קבלת תזכורת למייל הרשום באתר</span>
                                </div>
                            </label>
                            <div className='selectfield'>
                                <select
                                    value={reminder}
                                    onChange={e => setReminder(e.target.value)}
                                >
                                    {/* <option value="" disabled>Select time</option> */}
                                    {reminderTimeOptions.map(time => (
                                        <option key={time} value={time}>{time}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    )}


                </div>

                <div className='modal_footer'>
                    {eventExists && (
                        <p className='text-danger'>Event already exists</p>
                    )}
                    <div className="modal-actions">
                        <button onClick={() => { !addAll ? handleSave(itemData) : handleAllDaysSave(itemData) }} className="orangeButton">אישור</button>
                    </div>
                </div>

                {confirmationModal && (
                    <div className="modal-overlay confirmationModal" onClick={cancelConfirmation}>
                        <div className="modal-contents" onClick={e => e.stopPropagation()}>
                            <div className='modal_header'>
                                <span className="close" onClick={cancelConfirmation}>&times;</span>
                            </div>
                            <div className='modal_body text-center'>
                                <AlertIcon />
                                <h5>ישנן פעילויות מתוכננות לתאריך שבחרתם. </h5>
                                <p>ישנן פעילויות מתוכננות לתאריך שבחרתם. </p>
                            </div>

                            <div className='modal_footer'>
                                <div className="modal-actions">
                                    <button onClick={() => confirmOverRide(itemData)} className="orangeButton">אישור</button>
                                    <button onClick={() => cancelConfirmation} className="orangeButton">ביטול</button>
                                </div>
                            </div>

                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Modal;
