import SimpleCalendarIcon from "../assets/icons/simpleCalendarIcon";
import { Calendar, momentLocalizer } from "react-big-calendar";
import axios from "axios";
import { useState, useEffect } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSelector, useDispatch } from "react-redux";
import { updateListingData, updateCalendarDate } from "../utils/reducers/listing.reducer";
import moment from "moment";
import Modal from "./Modal";
import CalendarModal from "./CalendarModal";
import CloseIcon from "../assets/icons/CloseIcon";
import { Calendar as DropdownCalendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import SmallLoader from "./SmallLoader";

addLocale('he', {
  firstDayOfWeek: 0,
  dayNames: ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'],
  dayNamesShort: ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש'],
  dayNamesMin: ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש'],
  monthNames: ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'],
  monthNamesShort: ['ינו', 'פבר', 'מרץ', 'אפר', 'מאי', 'יונ', 'יול', 'אוג', 'ספט', 'אוק', 'נוב', 'דצ'],
  today: 'היום',
  clear: 'נקה'
});

const SidebarCalendar = (
    { 
        showCalendar, 
        handleCalendarButtonClick, 
        handleSelect,
        handleSave,
        openCalendar,
        setMainLoader,
        setIsLoading,
        isLoading,
    }) => {
    
    const { userData } = useSelector((state) => state.userData);
    const { listingData, calendarDate } = useSelector((state) => state.listingData);
    const dispatch = useDispatch();
    const localizer = momentLocalizer(moment);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [isOpenEditModal, setIsOpenEditModal] = useState(null);

    const [events, setEvents] = useState([]);

    const getCalendarData = async () => {
      setIsLoading(true);
        try {
            const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/wp-json/calendar-diary-api/user_data`,
            {
                params: { listing_id: listingData?.id, "user_id": userData?.userId },
                headers: { "Content-Type": "application/json" },
            }
            );
            if (response?.data?.status !== "error") {
                dispatch(updateListingData({sidebarCalendarData: response.data}))
            }else{
                dispatch(updateListingData({sidebarCalendarData: []}))
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            console.error("Error fetching recommendation data:", err);
        }
    };

    const udpateCalendarDate = (value) => {
      dispatch(updateCalendarDate(String(value)))
    }

    const handleEventClick = (event) => {
        setSelectedEvent(event);
        setIsModalOpen(true);
    }

    useEffect(() => {
        getCalendarData();
    }, []);

  //   useEffect(() => {
  //     // console.log("selectedEvent: ", selectedEvent);
  // }, [selectedEvent]);


    useEffect(() => {
        if(listingData?.sidebarCalendarData !== undefined && listingData?.sidebarCalendarData.length > 0){
            const convertedEvents = listingData.sidebarCalendarData.map(event => ({
                ...event,
                start: new Date(event.start),
                end: new Date(event.end),
                location: event.location,
                id: event.id
            }));
            setEvents(convertedEvents);
        }
    }, [listingData]);

    const formats = {
      timeGutterFormat: (date, culture, localizer) =>
        localizer.format(date, 'HH:mm', culture), // 24-hour format
      eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
        `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`, // Only time, 24-hour format
      agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
        `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`, // 24-hour format for agenda
    };

    // console.log("calendarDate: ", calendarDate);


    return (
        <>
        {!showCalendar && (
            <div className="fixcalendarbar">
              <div className="fixcalendarbar_top"></div>
              <div className="calendar-btn">
                <div className="calendar-img">
                  <SimpleCalendarIcon />
                </div>
                <span>יומן</span>
              </div>
              <div className="fixcalendarbar_bottom">
                <a href={`${process.env.REACT_APP_API_URL}/help-center`}>
                  <span className="fixcalendarbar_bottom_icon">
                    <svg
                      className="bi bi-question-circle-fill"
                      fill="currentColor"
                      height="16"
                      viewBox="0 0 16 16"
                      width="16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"></path>
                    </svg>
                  </span>
                </a>
              </div>
              <div
                className="calendar-close-icon"
                onClick={handleCalendarButtonClick}
              >
                <span></span>
              </div>
            </div>
        )}

        <div
            className={`eventCalendarMain ${showCalendar ? "calendarActive" : ""
              }`}
        >
            <div
              className="calendar-close-icon"
              onClick={handleCalendarButtonClick}
            ></div>

            <div className="topHeadSidebarCal">
              <span
                className="mobileCloseSide"
                onClick={handleCalendarButtonClick}
              >                
                <CloseIcon />
              </span>
              <h5>יומן</h5>
              {showCalendar && (
                <>
                  <Calendar
                    localizer={localizer}
                    events={events !== undefined ? events : []}
                    defaultView="day"
                    views={{ day: true }}
                    startAccessor="start"
                    endAccessor="end"
                    selectable
                    onSelectSlot={handleSelect}
                    style={{ height: 500 }}
                    defaultDate={calendarDate}
                    date={calendarDate}
                    onNavigate={udpateCalendarDate}
                    onSelectEvent={handleEventClick}
                    formats={formats}
                    components={{
                      event: ({ event }) => (
                        <span className="truncate-title" title={event.title}>
                          {event.title}
                        </span>
                      ),
                    }}
                  />
                  <div className="calendarDateDropdown">
                    <DropdownCalendar value={new Date(calendarDate)} locale="he" onChange={(e) => udpateCalendarDate(e.value)} />
                  </div>

                  <div
                    className="calendar-close-icon calendarOpen"
                    onClick={handleCalendarButtonClick}
                  >
                    <span></span>
                  </div>
                </>
              )}
              {isLoading && (
                <SmallLoader />
              )}
            </div>
          </div>

            {isOpenEditModal && (
                <Modal
                    isOpen={isOpenEditModal}
                    onClose={() => setIsOpenEditModal(false)}
                    onSave={handleSave}
                    start={selectedEvent?.start}
                    otherFields={true}
                    name={selectedEvent.title}
                    userId={userData?.userId}
                    isSidebarCalendar={true}
                    oldLocation={selectedEvent.location}
                    isUpdate={selectedEvent.id}
                    reminderVal={selectedEvent.calendar_reminder}
                    isRepeaterEvent={selectedEvent.acc_id !== "" ? true : false}
                    oldEndDate={selectedEvent?.end}
                    openCalendar={openCalendar}
                    setMainLoader={setMainLoader}
                    setIsCalendarLoading={setIsLoading}
                />
            )}

            {isModalOpen && (
                <CalendarModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    selectedEvent={selectedEvent}
                    openEditModal={setIsOpenEditModal}
                    getCalendarData={getCalendarData}
                />
              )}

        </>
    );
};

export default SidebarCalendar;
