import React, { useEffect, useState } from 'react';
import BannerImage from '../assets/images/home-banner.png';
import SpecImage from '../assets/images/specs.png';

import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import { AutoComplete } from "primereact/autocomplete";

const HomeBanner = () => {
    const [searchType, setSearchType] = useState('advertiser');
    const [activeTab, setActiveTab] = useState('');
    const [countries, setCountries] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedCountryData, setSelectedCountryData] = useState(null);
    const [cities, setCities] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedDurations, setSelectedDurations] = useState(null);
    const [tripStuitable, setTripStuitable] = useState([]);
    const [selectedTripSuitable, setSelectedTripSuitable] = useState([]);
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [whereYouTravelTab, setWhereYouTravelTab] = useState(false);
    const [searchUserVal, setSearchUserVal] = useState("");
    const [users, setUsers] = useState([]);
    const [userSuggestions, setUserSuggestions] = useState([]);
    const [userCountries, setUserCountries] = useState([]);
    const [userSelectedCountry, setUserSelectedCountry] = useState(null);
    const [userSelectedCountryData, setUserSelectedCountryData] = useState(null);
    const [userSelectedCities, setUserSelectedCities] = useState([]);

    const search = (event) => {
        setUserSelectedCities([])
        setUserSelectedCountryData(null);
        var searchTerm = event.query;
        const suggestions = users.filter((item) => {
            // Convert to lowercase for case-insensitive search
            const userName = item.label.toLowerCase(); 
            return userName.includes(searchTerm.toLowerCase());
        });

        setUserSuggestions(suggestions);
    }


    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.slug.toLowerCase()}`} style={{ width: '18px' }} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{selectedCountryData ? selectedCountryData.name : props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.slug.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.name}</div>
            </div>
        );
    };

    const userCountryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.text} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.slug.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.text}</div>
            </div>
        );
    };

    const toggleActiveTab = (tab) => {
        if(activeTab === tab){
            setActiveTab("");
        }else{
            setActiveTab(tab);
        }
    }

    const handleSearchTypeChange = (type) => {
        setSearchType(type);
    }

    const fetchSearchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/home/api`, {
                params: {
                    "action": "country"
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setCountries(response.data.data);
        } catch (err) {
            console.log(err);
        }
    }

    const getUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/home/api`, {
                params: {
                    "action": "users"
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setUsers(response.data.data);
        } catch (err) {
            console.log(err);
        }
    }

    const fetchUserCountries = async (userId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/home/api`, {
                params: {
                    "action": "user_country",
                    "user_id": userId
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setUserCountries(response.data.data);
        } catch (err) {
            console.log(err);
        }
    }

    const handleUserFieldChange = (event) => {
        setSearchUserVal(event.label); 
        fetchUserCountries(event.id)
    }

    const fetchStyleOfTrip = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/home/api`, {
                params: {
                    "action": "trip_suitable_for"
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setTripStuitable(response.data.data);
        } catch (err) {
            console.log(err);
        }
    };

    const getCountryCity = async (selectedCountryData) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/home/api`, {
                params: {
                    "action": "cities",
                    "country_id": [selectedCountryData.id]
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setCities(response?.data?.data);
        } catch (err) {
            console.log(err);
        }
    };

    const handleCityChange = (name, value, checked) => {
        const cities = [...selectedCities];
        var payload = [];
        const exits = cities.some(item => item.name === name)

        if(!exits && checked){
            payload = [
                ...cities,
                {name, value}
            ];
        }else{
            if(cities.length > 0){
                var index = cities.findIndex(item => item.name === name)
                if(index !== -1){
                    cities.splice(index, 1);
                }
            }
            payload = [
                ...cities
            ];
        }

        setSelectedCities(payload);
    }

    const handleUserCityChange = (name, value, checked) => {
        const cities = [...userSelectedCities];
        var payload = [];
        const exits = cities.some(item => item.name === name)

        if(!exits && checked){
            payload = [
                ...cities,
                {name, value}
            ];
        }else{
            if(cities.length > 0){
                var index = cities.findIndex(item => item.name === name)
                if(index !== -1){
                    cities.splice(index, 1);
                }
            }
            
            payload = [
                ...cities
            ];
        }

        setUserSelectedCities(payload);
    }

    const handleTripDuration = (value, checked) => {
        if(checked){
            setSelectedDurations(value)
        }else{
            setSelectedDurations(null)
        }

    }

    const handleTripSuitable = (value, checked, checkAll) => {
        const selectedTrips = [...selectedTripSuitable];
        var payload = [];

        if(!checkAll){
            const exits = selectedTrips.some(item => item.slug === value.slug)
            if(!exits && checked){
                payload = [
                    ...selectedTrips,
                    value
                ];
            }else{
                if(selectedTrips.length > 0){
                    var index = selectedTrips.findIndex(item => item.slug === value.slug)
                    if(index !== -1){
                        selectedTrips.splice(index, 1);
                    }
                }
                payload = [
                    ...selectedTrips
                ];
            }
        }else{
            if(checked){
                payload = [
                    ...tripStuitable,
                ];
            }else{
                payload = [];
            }
        }

        setSelectedTripSuitable(payload);
    }

    const submitSearch = async () => {
        setIsSearchLoading(true);
        const queryParams = new URLSearchParams();

        if(searchType === "advertiser"){
            queryParams.append('type', 'paid-vacation-itinerary');
            if (selectedCountry) queryParams.append('country', selectedCountry);
            if (selectedDurations) queryParams.append('trip_duration_category[]', selectedDurations);
            
            if(selectedCities.length > 0 && (
                await Promise.all(selectedCities.map((item) => {
                    queryParams.append('cities[]', item.name);
                }))
            ));
            
            if(selectedTripSuitable.length > 0 && (
                await Promise.all(selectedTripSuitable.map((item) => {
                    queryParams.append('trip_suitable_for[]', item.slug);
                }))
            ));
            
            var url = `${process.env.REACT_APP_API_URL}/explore/?${queryParams.toString()}`;

            window.location.href = url;
        }else{
            if (searchUserVal) queryParams.append('author', searchUserVal);
            if (userSelectedCountry) queryParams.append('country', userSelectedCountry);
            
            if(userSelectedCities.length > 0 && (
                await Promise.all(userSelectedCities.map((item) => {
                    queryParams.append('it_cities[]', item.name);
                }))
            ));
            
            var url = `${process.env.REACT_APP_API_URL}/search-vacations/?${queryParams.toString()}`;

            window.location.href = url;
        }

    }

    const autoCompleteItemTemplate = (item) => {
        return <p>{item.label}</p>;
    };

    useEffect(() => {
        fetchSearchData();
        fetchStyleOfTrip();
        getUsers();
    }, []);

    useEffect(() => {
        if(selectedCountryData){
            setSelectedCities([]);
            getCountryCity(selectedCountryData);
        }
    }, [selectedCountryData]);

    useEffect(() => {
        if(userSelectedCountryData){
            setSelectedCities([]);
            getCountryCity(userSelectedCountryData);
        }
    }, [userSelectedCountryData]);

    console.log("userSelectedCities: ", userSelectedCities);

    return (        
        <div className='homeBannerOuter pt-90'> 
            <div className='container'> 
                <div className='homeBanner d-flex flex-wrap justify-content-end position-relative'>
                    <div className='homeBannerImage position-absolute'>
                        <img src={BannerImage} alt='Loader Image' className='position-absolute h-100 w-100 top-0 start-0' />      
                        <div className='homeBannerTitle text-center position-absolute'>
                            <p className='text-white m-0'>תכנון  חופשה בקליק זה</p>
                            <h1 className='vacaytionTitle'>VACAYTIONS</h1>
                        </div>
                    </div>
                    <div className='homeBannerForm position-relative w-100'>
                        <ul className='homeBannerButtons d-flex align-items-center mb-md-4'>
                            <li className={searchType === "advertiser" ? "active" : ""}>
                                <button onClick={() => handleSearchTypeChange('advertiser')} className='orangeRadiusButton'>תכנון לפי יעד</button>
                            </li>
                            <li className={searchType === "destination" ? "active" : ""}>
                                <button onClick={() => handleSearchTypeChange('destination')} className='orangeRadiusButton'>תכנון לפי טרוולר</button>
                            </li>
                        </ul>
                        <div className='homeBannerFormWrap'>
                            {searchType === "advertiser" ? (
                                <div className='homeBannerFormWrapInner d-flex'>
                                    <div className='homeBannerFormRightFields d-flex'>                                    
                                        <div className={`homeBannerCommonField ${activeTab === "whereYourTravel" ? "openDropdown" : ""}`}>
                                            <div className='bannerCommonFieldLabel' onClick={() => toggleActiveTab("whereYourTravel")}>
                                                <span>
                                                    {selectedCountryData || selectedCities.length > 0 ? (<>
                                                        {selectedCountryData && selectedCountryData.name}
                                                        {selectedCities.length > 0 && (
                                                            selectedCities.map(item => (
                                                                `, ${item.name}`
                                                            ))
                                                        )}
                                                        </>
                                                    ) : "איפה מטיילים?"}
                                                </span>
                                            </div>  
                                            <div className='bannerCommonFieldDropdown'>
                                                <div className='bannerFieldDropdownHeader'>
                                                    <h5>איפה מטיילים?</h5>
                                                </div>
                                                <div className='bannerFieldDropdownContent styleScroll'>
                                                    <div className='formGroup'>
                                                        <label>מדינה</label>

                                                        <Dropdown 
                                                            value={selectedCountry} 
                                                            onChange={(e) => {setSelectedCountry(e.value.slug); setSelectedCountryData(e.value)}} 
                                                            options={countries} 
                                                            optionLabel="name" 
                                                            placeholder={"פורטוגל"} 
                                                            filter 
                                                            valueTemplate={selectedCountryTemplate} 
                                                            itemTemplate={countryOptionTemplate} 
                                                            className="w-full md:w-14rem" 
                                                        />

                                                    </div>
                                                    <div className='formGroup'>
                                                        <label>מיקום</label>
                                                        {(cities && cities.length > 0) && (
                                                            cities.map((item, key) => (
                                                                <div className='commonCheckbox'>
                                                                    <label className="commonCheckboxLabel">
                                                                        <input type="checkbox" checked={selectedCities.some(data=> data.name === item.name) ? true : false} value={item.slug} onChange={(e) => handleCityChange(item.name, e.target.value, e.target.checked)} />
                                                                        <span className="checkboxListStyleBox"></span>
                                                                        <span className='checkBoxFieldText'>{item.name}</span>
                                                                    </label>
                                                                </div>
                                                            ))
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='bannerFieldDropdownFooter'>
                                                <button className='smallblackButton' onClick={() => setActiveTab("")}>סגור</button>   
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`homeBannerCommonField ${activeTab === "durationOfTrip" ? "openDropdown" : ""}`}>
                                            <div className='bannerCommonFieldLabel' onClick={() => toggleActiveTab("durationOfTrip")}>
                                                <span>{selectedDurations ? selectedDurations : 'משך הטיול'}</span>
                                            </div>  

                                            <div className='bannerCommonFieldDropdown field2Dropdown'>
                                                <div className='bannerFieldDropdownHeader'>
                                                    <h5>משך הטיול</h5>
                                                </div>
                                                <div className='bannerFieldDropdownContent styleScroll'>
                                                    <div className='checkboxListStyle'>
                                                        <div className='commonCheckbox'>
                                                            <label className="commonCheckboxLabel">
                                                                <input type="checkbox" value="3-4-days" checked={selectedDurations === "3-4-days" ? true : false} onChange={(e) => handleTripDuration(e.target.value, e.target.checked)} />
                                                                <span className="checkboxListStyleBox"></span>
                                                                <span className='checkBoxFieldText'>3-4 ימים</span>
                                                            </label>
                                                        </div>
                                                        <div className='commonCheckbox'>
                                                            <label className="commonCheckboxLabel">
                                                                <input type="checkbox" value="5-7-days" checked={selectedDurations === "5-7-days" ? true : false} onChange={(e) => handleTripDuration(e.target.value, e.target.checked)} />
                                                                <span className="checkboxListStyleBox"></span>
                                                                <span className='checkBoxFieldText'>5-7 ימים</span>
                                                            </label>
                                                        </div>
                                                        <div className='commonCheckbox'>
                                                            <label className="commonCheckboxLabel">
                                                                <input type="checkbox" value="8-10-days" checked={selectedDurations === "8-10-days" ? true : false} onChange={(e) => handleTripDuration(e.target.value, e.target.checked)} />
                                                                <span className="checkboxListStyleBox"></span>
                                                                <span className='checkBoxFieldText'>8-10 ימים</span>
                                                            </label>
                                                        </div>
                                                        <div className='commonCheckbox'>
                                                            <label className="commonCheckboxLabel">
                                                                <input type="checkbox" value="11-14-days" checked={selectedDurations === "11-14-days" ? true : false} onChange={(e) => handleTripDuration(e.target.value, e.target.checked)} />
                                                                <span className="checkboxListStyleBox"></span>
                                                                <span className='checkBoxFieldText'>11-14 ימים</span>
                                                            </label>
                                                        </div>
                                                        <div className='commonCheckbox'>
                                                            <label className="commonCheckboxLabel">
                                                                <input type="checkbox" value="15-30-days" checked={selectedDurations === "15-30-days" ? true : false} onChange={(e) => handleTripDuration(e.target.value, e.target.checked)} />
                                                                <span className="checkboxListStyleBox"></span>
                                                                <span className='checkBoxFieldText'>15-30 ימים</span>
                                                            </label>
                                                        </div>
                                                    </div>                                            
                                                </div>
                                                <div className='bannerFieldDropdownFooter'>
                                                <button className='smallblackButton' onClick={() => setActiveTab("")}>סגור</button>   
                                                </div>
                                            </div>
                                        </div> 
                                        {/* <div className={`homeBannerCommonField ${activeTab === "styleOfTrip" ? "openDropdown" : ""}`}>
                                            <div className='bannerCommonFieldLabel' onClick={() => toggleActiveTab("styleOfTrip")}>
                                                <span>{selectedTripSuitable.length > 0 ? selectedTripSuitable.map((item, key) => (
                                                    `${item.name}${selectedTripSuitable.length !== key+1 ? ", ": ""}`
                                                )): "סגנון הטיול"}</span>
                                            </div>  
                                            <div className='bannerCommonFieldDropdown field3Dropdown'>
                                                <div className='bannerFieldDropdownHeader'>
                                                    <h5>סגנון הטיול</h5>
                                                </div>
                                                <div className='bannerFieldDropdownContent styleScroll'>
                                                    <div className='checkboxListStyle column2'>
                                                        {tripStuitable && tripStuitable.map((item, key) => (
                                                            <div className='commonCheckbox'>
                                                                <label className="commonCheckboxLabel">
                                                                    <input 
                                                                        type="checkbox" 
                                                                        value={item.slug} 
                                                                        checked={selectedTripSuitable.some(data=> data.slug === item.slug) ? true : false}
                                                                        onChange={(e) => handleTripSuitable(item, e.target.checked, item.slug === "all-holidays" ? true : false)}
                                                                    />
                                                                    <span className="checkboxListStyleBox"></span>
                                                                    <span className='checkBoxFieldText'>{item.name}</span>
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>                                            
                                                </div>
                                                <div className='bannerFieldDropdownFooter'>
                                                <button className='smallblackButton' onClick={() => setActiveTab("")}>סגור</button>   
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className='homeBannerSubmit'>
                                        <button type='submit' 
                                            className={`homeBannerSubmitButton d-flex align-items-center justify-content-center  position-relative ${isSearchLoading && "showLoader"}`} 
                                            disabled={(!selectedCountry && selectedCities.length <= 0 && !selectedDurations && selectedTripSuitable.length <= 0) ? true : false} 
                                            onClick={submitSearch}
                                        >
                                            <span className='homeBannerSubmitIcon'>
                                                <i className='fas fa-search'></i>
                                            </span> 
                                            <span className='showOnMobile'>חפש</span>                                        
                                            <div className='homeBannerSubmitLoader buttonLoader'>
                                                <i className='fas fa-sync'></i>
                                            </div>
                                        </button>
                                    </div>                                
                                </div>
                            ) : (
                                <div className='homeBannerFormWrapInner d-flex'>
                                    <div className='homeBannerFormRightFields d-flex'>
                                        <div className='homeBannerCommonField bannerInputfld'>
                                        <AutoComplete 
                                            value={searchUserVal} 
                                            suggestions={userSuggestions} 
                                            completeMethod={search} 
                                            placeholder='הקלידו שם ובחרו מהרשימה'
                                            onChange={(e) => handleUserFieldChange(e.value)} 
                                            itemTemplate={autoCompleteItemTemplate}
                                        />
                                        </div>
                                        <div className={`homeBannerCommonField ${whereYouTravelTab ? "openDropdown" : ""}`}>
                                            <div className='bannerCommonFieldLabel' onClick={() => setWhereYouTravelTab(!whereYouTravelTab)}>
                                                <span>
                                                    {userSelectedCountryData || userSelectedCities.length > 0 ? (<>
                                                        {userSelectedCountryData && userSelectedCountryData.text}
                                                        {userSelectedCities.length > 0 && (
                                                            userSelectedCities.map(item => (
                                                                `, ${item.name}`
                                                            ))
                                                        )}
                                                        </>
                                                    ) : "איפה מטיילים?"}
                                                </span>
                                            </div>  
                                            <div className='bannerCommonFieldDropdown'>    
                                                <div className='bannerFieldDropdownHeader'>
                                                    <h5>איפה מטיילים?</h5>
                                                </div>                                            
                                                <div className='bannerFieldDropdownContent styleScroll'>
                                                    <div className='formGroup'>
                                                        <label>מדינה</label>

                                                        <Dropdown 
                                                            value={userSelectedCountry} 
                                                            onChange={(e) => {setUserSelectedCountry(e.value.slug); setUserSelectedCountryData(e.value)}} 
                                                            options={userCountries} 
                                                            optionLabel="text" 
                                                            placeholder={"פורטוגל"} 
                                                            filter 
                                                            valueTemplate={selectedCountryTemplate} 
                                                            itemTemplate={userCountryOptionTemplate} 
                                                            className="w-full md:w-14rem" 
                                                        />

                                                    </div>
                                                    <div className='formGroup'>
                                                        <label>מיקום</label>
                                                        {(cities && cities.length > 0) && (
                                                            cities.map((item, key) => (
                                                                <div className='commonCheckbox'>
                                                                    <label className="commonCheckboxLabel">
                                                                        <input type="checkbox" checked={userSelectedCities.some(data=> data.name === item.name) ? true : false} value={item.slug} onChange={(e) => handleUserCityChange(item.name, e.target.value, e.target.checked)} />
                                                                        <span className="checkboxListStyleBox"></span>
                                                                        <span className='checkBoxFieldText'>{item.name}</span>
                                                                    </label>
                                                                </div>
                                                            ))
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='bannerFieldDropdownFooter'>
                                                    <button className='smallblackButton' onClick={() => setWhereYouTravelTab(false)}>סגור</button>   
                                                </div>
                                            </div>
                                        </div>
                                        

                                    </div>
                                    <div className='homeBannerSubmit'>
                                        <button type='submit' 
                                            className={`homeBannerSubmitButton d-flex align-items-center justify-content-center position-relative ${isSearchLoading && "showLoader"}`} 
                                            disabled={(!selectedCountry && selectedCities.length <= 0 && !selectedDurations && selectedTripSuitable.length <= 0 && !userSelectedCountry) ? true : false} 
                                            onClick={submitSearch}
                                        >
                                            <span className='homeBannerSubmitIcon'>
                                                <i className='fas fa-search'></i>
                                            </span> 
                                            <span className='showOnMobile'>חפש</span>                                        
                                            <div className='homeBannerSubmitLoader buttonLoader'>
                                                <i className='fas fa-sync'></i>
                                            </div>
                                        </button>
                                    </div>                                
                                </div>
                            )}                            
                        </div>
                    </div>
                    
                </div>
                <div className='specsBottom mt-3 d-none d-md-flex align-items-center justify-content-center'>
                    <a href={`/listing/long-weekend-in-barcelona/`} className='text-dark text-decoration-underline'>
                        <img src={SpecImage} alt='Specs' className='specsIcon ms-3' width={40} />לצפייה בתיק טיול מלא לדוגמה
                    </a>
                </div>
            </div>
        </div>
    );
}

export default HomeBanner;