import { Link, useNavigate } from "react-router-dom";
import BlackPlusIcon from "../assets/icons/BlackPlusIcon";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import HelpIcon from '@mui/icons-material/Help';
import Logo from "../assets/images/logo_black.png";
import { useEffect, useState } from "react";
import axios from "axios";
import NotificationSideBar from "../components/NotificationSideBar";
import PersonIcon from '@mui/icons-material/Person';

const Header = ({ isMobileMenuOpen, listingData }) => {

    // console.log("under_header: test ", userId);

    const [menuData, setMenuData] = useState([]);
    const [userName, setUserName] = useState(); // State for storing user name
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to manage NotificationSideBar visibility
    const [notificationCount, setNotificationCount] = useState(0); // State for storing notification count
    const [userAvatar, setUserAvatar] = useState(""); // State for storing user avatar
    const [activeItem, setActiveItem] = useState(false); // State for storing user avatar
    const [userId, setUserId] = useState(0);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const navigate = useNavigate(); // Using the useNavigate hook

    const getHeaderData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/wp-json/menu/show`);
            const data = await response.json();
            if (data.success) {
                let filteredMenu = data.header_menu.filter(item => item.ID !== 12175);

                const hasParent12176 = filteredMenu.some(item => item.ID === 12176);
                if (hasParent12176) {
                    filteredMenu = filteredMenu.map(item => {
                        if (item.children[1].ID === 12172) {
                            return {
                                ...item,
                                children: item.children.filter(child => child.ID !== 12172) // Remove child with ID 12172
                            };
                        }
                        return item;
                    });
                }
                setMenuData(filteredMenu);
            }
        } catch (error) {
            console.error("Error fetching menu data:", error);
        }
    }; 

    const getUserData = async () => {
        try { 
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/user/check-login`);
                if (response.data.data.status === 200) {
                    setUserName(response.data.data.display_name);
                    setUserAvatar(response.data.data.user_avatar);
                    setUserId(response.data.data.user_id)
                    setIsDataLoading(false)
            } else {
                setUserId(1)
                setUserName(""); 
                setUserAvatar(""); 
                setIsDataLoading(false)
            }
        } catch (error) {
            setUserId(1)
            setUserName(""); 
            setUserAvatar(""); 
            setIsDataLoading(false)
        }  
    }; 

    const getNotificationCount = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/notifications/show`, {
                params: {
                    user_id: userId,
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const notificationData = response.data;
            setNotificationCount(notificationData.active_notifications); // Store the count
        } catch (error) {
            console.error("Error fetching notifications count:", error);
        }
    };

    const toggleMenuItem = (item) => {
        setActiveItem(activeItem ? false : item);
    }

    useEffect(() => {
        getHeaderData();
        getUserData(); // Fetch user data when the component mounts
        getNotificationCount(); // Fetch notification count when the component mounts
    }, []);

    // useEffect(() => {
    //     getUserData();
    // }, [userId]);

    // Function to toggle sidebar visibility
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handlePersonIconClick = () => {
        navigate(`${process.env.REACT_APP_API_URL}/login/?mode=login`); // Redirect to login page
    };

    return (
        <>
            <div className="siteMainHeader py-0">
                <div className="container">
                    <div className="forlaptop d-flex justify-content-end">
                        <a href={`${process.env.REACT_APP_API_URL}/explore/?type=paid-vacation-itinerary`} className="d-flex align-items-center text-dark">
                            <span>חזרה </span>
                            <i className="fas fa-chevron-left me-2"></i>
                        </a>
                    </div>
                    <div className={`siteMainHeaderInner d-flex align-items-center ${!listingData && "otherPages"}`}>
                        <div className="headerTopNav">
                            <ul className="d-flex">
                                {menuData.map((menuItem) => (
                                    <li key={menuItem.ID} className="menu-item-has-children">
                                        <a href={menuItem.link}>{menuItem.name}</a>
                                        {menuItem.children && menuItem.children.length > 0 && (
                                            <ul className="sub-menu">
                                                {menuItem.children.map((childItem) => (
                                                    <li key={childItem.ID}>
                                                        <a href={childItem.link}>{childItem.name}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="headerTopLogo text-center">
                            <a href={`/`}>
                                <img
                                    src={Logo}
                                    className="siteImage"
                                    alt="siteImg"
                                />
                            </a>
                        </div>

                        <div className="headerTopAction d-flex align-items-center justify-content-end">
                            <ul className="d-flex align-items-center justify-content-end">
                                <li>
                                    <a href={`${process.env.REACT_APP_API_URL}/help-center`}>
                                        <i><HelpIcon /></i>
                                    </a>
                                </li>
                                <li>
                                    <nav className="brk-nav brk-nav-notifications">
                                    <ul>
                                        <li>
                                            {userName && userAvatar ? (
                                                <a href="#" className="brk--pad" onClick={toggleSidebar}>
                                                    <i>
                                                        <NotificationImportantIcon />
                                                        {notificationCount > 0 && (
                                                            <span className="notification-badge">{notificationCount}</span> // Show notification count
                                                        )}
                                                    </i>
                                                </a>
                                            ) : (
                                                
                                                <a href={`${process.env.REACT_APP_API_URL}/login/?mode=login`} style={{pointerEvents: isDataLoading ? "none" : "all"}}>
                                                    <i>
                                                        <PersonIcon />
                                                    </i>
                                                </a>
                                            )}
                                        </li>
                                    </ul>
                                </nav>

                                </li>
                                <li>
                                    <nav className="brk-nav brk-nav-user">
                                        <ul>
                                            <li className="menu-item-has-children">
                                                {userName && userAvatar && (

                                                    <a href={`${process.env.REACT_APP_API_URL}/my-account/`} className="d-flex align-items-center">
                                                        <picture>
                                                            <img
                                                                src={userAvatar || `${process.env.REACT_APP_API_URL}/wp-content/uploads/2023/04/d4k9unor1mhgja8l30e6qiyv75psxfzw-150x150.jpg`}
                                                                alt="User Avatar"
                                                            />
                                                        </picture>
                                                        <span>{userName || "VACAYTIONS"}</span>
                                                    </a>
                                                )}

                                                <ul className="sub-menu">
                                                    {/* Sub-menu items */}
                                                    <li>
                                                        <a href={`${process.env.REACT_APP_API_URL}/my-account/`}> אזור אישי </a>
                                                    </li>
                                                    <li>
                                                        <a href={`${process.env.REACT_APP_API_URL}/my-account/messages/`}> הודעות </a>
                                                    </li>
                                                    <li>
                                                        <a href={`${process.env.REACT_APP_API_URL}/my-account/listings/`}> הפרסומים שלי </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" data-modal="favorites"> מועדפים </a>
                                                    </li>
                                                    <li className="rz--separator"></li>
                                                    <li>
                                                        <a href={`${process.env.REACT_APP_API_URL}/my-account/orders/`}> הזמנות </a>
                                                    </li>
                                                    <li>
                                                        <a href={`${process.env.REACT_APP_API_URL}/my-account/payouts/`}> תשלומים </a>
                                                    </li>
                                                    <li className="rz--separator"></li>
                                                    <li>
                                                        <a href={`${process.env.REACT_APP_API_URL}/help-center`} target="_blank">מרכז העזרה</a>
                                                    </li>
                                                    <li>
                                                        <a href={`${process.env.REACT_APP_API_URL}/my-account/edit-account/`}> פרטי חשבון </a>
                                                    </li>
                                                    <li>
                                                        <a href={`${process.env.REACT_APP_API_URL}/affiliate-dashboard`}>תכנית שותפים</a>
                                                    </li>
                                                    <li>
                                                        <a href={`${process.env.REACT_APP_API_URL}/logout.php`}> להתנתק </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </nav>
                                </li>
                                <li className="add_listing">
                                    <div className="newSubmission">
                                        {userId == 0 ? (
                                            <button className="btn d-flex justify-content-center align-items-center">
                                                <a href={`${process.env.REACT_APP_API_URL}/login/?mode=login`} style={{pointerEvents: isDataLoading ? "none" : "all"}}>
                                                    <span>צור חדש</span>
                                                    <BlackPlusIcon />
                                                </a>
                                            </button>
                                        ) : (
                                            <button className="btn d-flex justify-content-center align-items-center">
                                                <a href={`${process.env.REACT_APP_API_URL}/submission/`}>
                                                    <span>צור חדש</span>
                                                    <BlackPlusIcon />
                                                </a>
                                            </button>
                                        )}

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`headerTopNavMobile ${isMobileMenuOpen && "active"}`}>
                <div className="headerTopNavMobileHeader">
                    <div className="p-2">
                        <a href="/">
                        <img
                            src={Logo}
                            alt="siteImg"
                        />
                        </a>
                    </div>
                </div>
                <ul className="mainMobilemenu">
                    {menuData.map((menuItem) => (
                        <li key={menuItem.ID} className={`menu-item-has-children ${activeItem === menuItem.ID && "active"}`}>
                            <a href="#" onClick={() => toggleMenuItem(menuItem.ID)}>{menuItem.name}</a>
                            {menuItem.children && menuItem.children.length > 0 && (
                                <ul className="mobile-sub-menu">
                                    {menuItem.children.map((childItem) => (
                                        <li key={childItem.ID}>
                                            <Link to={childItem.link}>{childItem.name}</Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </div>

            {/* Conditionally render NotificationSideBar based on state */}
            {isSidebarOpen && <NotificationSideBar userId={userId} />}
        </>
    );
}

export default Header;
