import LodgingIcon from "../../assets/icons/LodgingIcon";
import SubLodgingIcon from "../../assets/icons/SubLodgingIcon";
import { getLodgingFeatureIcon } from "../../utils/getIcon";
import getFeatureName from "../../utils/getFeatureName";
import CalendarIcon from "../../assets/icons/CalendarIcon";
import ImageGallery from "../ImageGallery";
import { useSelector } from "react-redux";

const LodgingRepeater = ({data, tabRef, updateHover, openCalendar, setIsCalendarLoading, setMainLoader}) => {
    const { listingData } = useSelector((state) => state.listingData);
    
    return (
        <div className="tabPanel" ref={tabRef}>
            <div className="aboutTabPanel">
                <div className="singleListingPanelTitleIcon d-flex">
                    <div className="title_icon">
                        <LodgingIcon />
                    </div>
                    <h3>לינה</h3>                    
                </div>
                {/* <CalendarIcon height={40} width={40} /> */}

                <div className="repeaterFields">
                        {data && (
                            data.map((item, key) => (
                                
                                <div className={`singleRepeater rz_accommodation${key} mb-5`} key={`accomodation-${key}`} 
                                    onMouseEnter={() => updateHover({rzType: "rz_accommodation", placeId: item?.rz_accommodation_full_address?.place_id})}
                                    onMouseLeave={() => updateHover({})} 
                                >
                                    <div className="singleListingPanelTitleIcon d-flex">
                                        <div className="title_icon">
                                            <div className="subpanelIcon">
                                                <SubLodgingIcon />
                                            </div>                                            
                                        </div>
                                        <div className="singleListingPanelSummary">
                                            <div className="reapterTitle" >
                                                <h5>{item.rz_accommodation_name}</h5>
                                                {listingData?.id !== 17147 && (
                                                    <CalendarIcon name={item.rz_accommodation_name} height={40} width={40} accId={"rz_accommodation"} index={key} itemData={item} openCalendar={openCalendar} setIsCalendarLoading={setIsCalendarLoading} setMainLoader={setMainLoader} />
                                                )}
                                            </div>
                                            <div className="repeaterAddress">                                                
                                                {item?.rz_accommodation_full_address?.address} - <a href={
                                                    `https://www.google.com/maps/search/?api=1&query=${item?.rz_accommodation_full_address?.address}&query_place_id=${item?.rz_accommodation_full_address?.place_id}`
                                                }>צפיה במפה</a>
                                            </div>
                                            {item?.rz_accommodation_gallery && (
                                                <div className="repeaterGalleryData">
                                                    <ImageGallery
                                                    images={item?.rz_accommodation_gallery}
                                                    showBadge={false}
                                                    rec_price_range = {item?.rec_price_range[0]}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                    </div>

                                    
                                    <div className="repeaterHighLightFeatures d-flex">
                                        {item?.rz_acc_features_highlight && (
                                            item?.rz_acc_features_highlight.map((hightLight, hightLightKey) => (
                                                <div className="listingTabFeatureItem" key={hightLightKey}>
                                                    <div className="highLightFeature d-flex flex-column align-items-center gap-1" key={hightLightKey}>
                                                        <div className="featureIcons"
                                                            dangerouslySetInnerHTML={{
                                                                __html: getLodgingFeatureIcon(hightLight) && getLodgingFeatureIcon(hightLight)[hightLight]
                                                            }}
                                                        />
                                                        <p>{getFeatureName(hightLight,'acc')} </p>                                                   
                                                    </div>                                                   
                                                </div>
                                            ))
                                        )}
                                    </div>
                                    <div className="singleListingPanelSummary">
                                        <div className="reapterDescription"
                                            dangerouslySetInnerHTML={{
                                                __html: item.rz_accommodation_description
                                            }}
                                        />

                                        <div className="listingTabActionButtons d-flex">
                                            {item.rz_accommodation_reservaction_link !== "" &&(
                                                <div className="reservactionLink">
                                                    <a href={item.rz_accommodation_reservaction_link} className="btn btn-secondary">להזמנה</a>
                                                </div>
                                            )}

                                            {item.rz_accommodation_website_link !== "" &&(
                                                <div className="reservactionLink">
                                                    <a href={item.rz_accommodation_website_link} className="btn btn-primary">לאתר</a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                </div>
            </div>

            <hr />
        </div>
    );
}

export default LodgingRepeater;