import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const NotificationSideBar = ({userId}) => {
    let user=userId;
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [notifications, setNotifications] = useState([]);

    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/wp-json/notifications/show`, {
                    params: {
                        user_id: user,
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.data.status === "success") {
                    setNotifications(response.data.notifications);
                }
            } catch (error) {
                console.error("Error fetching notifications:", error);
            }
        };

        fetchNotifications();
    }, []);

    const markNotificationAsRead = async (noti_id) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/wp-json/notifications/update`, {
                noti_id: noti_id,
                user_id: user
            });

            setNotifications((prevNotifications) =>
                prevNotifications.map((notification) =>
                    notification.id === noti_id ? { ...notification, active: 0 } : notification
                )
            );
        } catch (error) {
            console.error("Error marking notification as read:", error);
        }
    };

    const markAllAsRead = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/wp-json/notifications/update/all`, {
                user_id: user
            });

            setNotifications((prevNotifications) =>
                prevNotifications.map((notification) => ({ ...notification, active: 0 }))
            );
        } catch (error) {
            console.error("Error marking all notifications as read:", error);
        }
    };
    return (
        isSidebarOpen && (
            <>
            <div className="alertSidebarWrapper active">
                <div className="alertSidebarContent">
                    <div className="alertSidebarHeader d-flex align-items-center justify-content-between">
                        <h4 className="m-0">התראות</h4>
                        <span className="alertPopupClose closeCircle" onClick={closeSidebar}>
                            <i className="fas fa-times"></i>
                        </span>
                    </div>
                    <div className="alertSidebarAction">
                    {notifications.length > 0 && (
                        <button onClick={markAllAsRead} className="blackRadiusButton">
                            <i className="fas fa-check ms-2"></i>
                            סמן הכל כנקרא
                        </button>
                    )}
                    </div>
                    <div className="alertSidebarContentInner styleScroll">
                        <div className="alertSidebarNotifications">
                            {notifications.length > 0 ? (
                                notifications.map((notification) => (
                                    <div key={notification.id} className="commonSidebarNotification">
                                        <a
                                            href={notification.meta.url}
                                            className="d-flex align-items-center"
                                            onClick={() => markNotificationAsRead(notification.id)}
                                        >
                                            <div className="commonNotificationIcon">
                                                <i className={notification.meta.icon}></i>
                                            </div>
                                            <div className="commonNotificationText d-flex justify-content-between">
                                                <div>
                                                    <h6>{notification.meta.msg}</h6>
                                                    <span className="notificationDate">
                                                        {new Date(notification.created_at).toLocaleString("he-IL", {
                                                            dateStyle: "medium",
                                                            timeStyle: "short",
                                                        })}
                                                    </span>
                                                </div>
                                                {/* Show blue dot if notification is active */}
                                                {notification.active === "1" && (
                                                    <span className="blue-dot"></span>
                                                )}
                                            </div>
                                        </a>
                                    </div>
                                ))
                            ) : (
                                <p className="mx-4">אין התראות זמינות</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="alertSidebarOverlay" ></div>
            </>
        )
    );
};

export default NotificationSideBar;
