import React, { useEffect, useState } from "react";
import TripSummaryIcon from "../assets/icons/TripSummaryIcon";
import WhoAmINavIcon from "../assets/icons/WhoAmINavIcon";
import LodgingIcon from "../assets/icons/LodgingIcon";
import CulinaryIcon from "../assets/icons/CulinaryIcon";
import AttractionIcon from "../assets/icons/AttractionIcon";
import NightLifeIcon from "../assets/icons/NightLifeIcon";
import ShoppingIcon from "../assets/icons/ShoppingIcon";
import MapIcon from "../assets/icons/MapIcon";
import CalendarIconWithoutModal from "../assets/icons/CalendarIconWithoutModal";
import FAQIcon from "../assets/icons/FAQIcon";
import ReviewsIcon from "../assets/icons/ReviewsIcon";

const ScrollingTabs = ({
  summaryTab,
  whoAmITab,
  lodgingTab,
  culinaryTab,
  attractionTab,
  nightLifeTab,
  shoppingTab,
  mapTab,
  calendarTab,
  faqTab,
  reviewsTab,
  userId,
  listingData,
  showBlur,
}) => {
  const [currentActiveTab, setCurrentActiveTab] = useState("summary");
  const [width, setWidth] = useState(window.innerWidth);

  // All tab elements with their refs
  const tabs = [
    { name: "summary", ref: summaryTab, icon: <TripSummaryIcon />, label: "תקציר הטיול" , display: true},
    { name: "whoAmI", ref: whoAmITab, icon: <WhoAmINavIcon />, label: "מי אני" , display: true},
    { name: "lodging", ref: lodgingTab, icon: <LodgingIcon />, label: "לינה" , display: listingData?.acf?.rz_accommodation[0].rz_accommodation_name != "" ? true : false},
    { name: "culinary", ref: culinaryTab, icon: <CulinaryIcon />, label: "קולינריה" , display: listingData?.acf?.rz_culinary[0].rz_culinary_name != "" ? true : false},
    { name: "attraction", ref: attractionTab, icon: <AttractionIcon />, label: "אטרקציות" , display: listingData?.acf?.rz_attractions[0].rz_attractions_name != "" ? true : false},
    { name: "nightLife", ref: nightLifeTab, icon: <NightLifeIcon />, label: "חיי לילה" , display: listingData?.acf?.rz_night[0].night_life_name != "" ? true : false},
    { name: "shopping", ref: shoppingTab, icon: <ShoppingIcon />, label: "שופינג" , display: listingData?.acf?.rz_shopping[0].rz_shopping_name != "" ? true : false},
    { name: "map", ref: mapTab, icon: <MapIcon />, label: "מפה מפורטת" , display: true},
    { name: "calendar", ref: calendarTab, icon: <CalendarIconWithoutModal />, label: "לו\"ז לדוגמא" , display: true},
    { name: "faq", ref: faqTab, icon: <FAQIcon />, label: "שאלות ותשובות" , display: true},
    { name: "reviews", ref: reviewsTab, icon: <ReviewsIcon />, label: "ביקורות" , display: true},
  ];


  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 641;

  // Scroll to section with sticky header offset
  const scrollToTab = (ref, tabName) => {
    const headerHeight = isMobile ? 170 : 200; // Adjust for header height
    if(ref.current){
      const sectionTop = ref.current.getBoundingClientRect().top + window.pageYOffset;
      
      window.scrollTo({
        top: sectionTop - headerHeight,
        behavior: "smooth",
      });
    }

    // Set the clicked tab as active
    setCurrentActiveTab(tabName);
  };

  // Handle tab activation while scrolling
  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = isMobile ? 180 : 210;
      let currentTab = "summary"; // Default tab

      tabs.forEach(({ name, ref }) => {

        if(ref.current){
          const sectionTop = ref.current.getBoundingClientRect().top - headerHeight;
          if (sectionTop <= 0) {
            currentTab = name;
          }
        }
      });

      setCurrentActiveTab(currentTab);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [tabs]);

  console.log("scrolling listingData: ", listingData.acf);

  useEffect(() => {
    let lastScrollTop = 0;
  
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      const scrollingtabs = document.getElementById("scrollingtabs");

      if (currentScrollTop > lastScrollTop) {
        // User is scrolling down
        if (scrollingtabs) {
          if (isMobile && currentActiveTab === "attraction") {
            scrollingtabs.scrollLeft = -320; 
          }
          if (isMobile && currentActiveTab === "calendar") {
            scrollingtabs.scrollLeft = -520; 
          }
        }
      } else {   
        // User is scrolling up
        if (scrollingtabs) {
          if (isMobile && currentActiveTab === "culinary") {
            scrollingtabs.scrollLeft = 320; 
          }
          if (isMobile && currentActiveTab === "calendar") {
            scrollingtabs.scrollLeft = -420; 
          }
        }
      }
      // Update last scroll position
      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; 
    };
  
    window.addEventListener("scroll", handleScroll);
      return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobile, currentActiveTab]);
  
  


  return (
    <div className="scrollingTabsWrapper">
      <div className="container d-flex align-items-start scrollingTabsContainer">
        <div className="scrollingTabsWrapperInner d-flex align-items-center" id="scrollingtabs">
          {tabs.map(({ name, ref, icon, label, display }) => (
            display && ( <>

              <div
                key={name}
                className={`singleScrollingTab ${currentActiveTab === name ? "active" : ""}`}
                onClick={() => scrollToTab(ref, name)}
                >
                <div className="d-flex justify-content-end align-items-center flex-column">
                  <div className="navIcon">{icon}</div>
                  <p className="mb-0">{label}</p>
                </div>
              </div>
            </> )
          ))}
        </div>

        {/* Login/Cart Button */}
        {userId === 0 ? (
          <a href={`${process.env.REACT_APP_API_URL}/login/?listing_id=${listingData.id}`} className="headerLoginBtn">
            לרכישה
          </a>
        ) : (
          showBlur && (
            <a href={`${process.env.REACT_APP_API_URL}/add-to-cart.php?listing_id=${listingData.id}`} className="headerLoginBtn">
              לרכישה
            </a>
          )
        )}
      </div>
    </div>
  );
};

export default ScrollingTabs;
